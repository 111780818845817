import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment'; // Angular CLI environment
import { EffectsModule } from '@ngrx/effects';
import { ClipboardModule } from 'ngx-clipboard';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { DeviceEffects } from './effects/device.effects';
import { PropertyEffects } from './effects/property.effects';
import { AuthEffects } from './effects/auth.effects';
import { authReducer } from './reducers/auth.reducer';
import { AuthConfig, AuthModule } from '@auth0/auth0-angular';

// Build the URL that Auth0 should redirect back to
// const redirectURI = `https://johanrossouw.com`;
const redirectURI = `http://localhost:8100`;
// const authConfig: AuthConfig = {
//   domain: 'dev-slmk4er5kbx6owjp.us.auth0.com',
//   clientId:'BC3ZK12XHwb6k8mDSNln9mRTZ5SDDff2',
//   authorizationParams: {
//     redirect_uri: redirectURI,
//   },
//   // For using Auth0-Angular with Ionic on Android and iOS,
//   // it's important to use refresh tokens without the falback
//   useRefreshTokens: true,
//   useRefreshTokensFallback: false,
// };
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([DeviceEffects, AuthEffects, PropertyEffects]),
    AppRoutingModule,
    HttpClientModule,
    ClipboardModule,
    StoreModule.forFeature('auth', authReducer),
    // AuthModule.forRoot(authConfig),
    AuthModule.forRoot({
      domain: 'dev-slmk4er5kbx6owjp.us.auth0.com',
      clientId: 'DjPhiLktRRnIWLNw2TcmejGRufUwy6SY',
      useRefreshTokens: true,
      useRefreshTokensFallback: false,
      authorizationParams: {
        // redirect_uri: redirectURI,
        redirect_uri: window.location.origin,
      }
    })
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
